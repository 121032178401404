import { Injectable } from '@angular/core';
import { Router } from "@angular/router";
import { HttpClient } from '@angular/common/http';
import { environment } from 'environments/environment';
import { of as observableOf,  Observable,  BehaviorSubject, Subject } from 'rxjs';
import { tap } from "rxjs/operators";
import { Course, Module, Lesson, Masterclass, NextStep, CourseData } from '../data/courses';
import { ApiResponse } from 'app/@core/data/api-response';
import { ProgressData } from 'app/@core/data/progress';
import { EventData } from 'app/@core/data/events';
import { FaqData } from 'app/@core/data/faqs';
import { DirectoryData } from 'app/@core/data/directory';

@Injectable()
export class CoursesService extends CourseData {

  baseUrl: string = environment.baseUrl + 'courses/';
  defaultValue:Course = {'id':0, 'name': '', 'slug': '', 'description': '', 'start_date': '', 'end_date': '', 'attendee': 0, 'options': {logo:'', is_cohort:false, actionplan:false, directory: false, slack:'', beacon: '', mist: ''}, 'modules': [], 'masterclasses': [], 'course_startdate':new Date() ,'course_enddate': new Date(), 'course_status':0, 'progress':0};

  private _courses = new BehaviorSubject<Course[]>([]);
  private coursesStore: { courses: Course[] } = { courses: [] };
  readonly courses = this._courses.asObservable();

  private _course = new BehaviorSubject<Course>(this.defaultValue);
  private courseStore: { course: Course } = { course: this.defaultValue};
  readonly course = this._course.asObservable();

  private breadcrumbs = new Subject<any>();
  private module = new Subject<any>();

  private jumbotronSource:BehaviorSubject<boolean>  = new BehaviorSubject<boolean>(true);
  jumbotron = this.jumbotronSource.asObservable();
  hasTygpro: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  hasAces: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  private _pwyccourses:Array<Array<number>> = [[33,34,35,36], [54]];

  constructor(
    private router: Router,
    private http: HttpClient,
    private progressService: ProgressData,
    private directoryService:DirectoryData,
    private eventService: EventData,
    private faqService: FaqData,
  ) {
    super()
  }

  showJumbotron(message: boolean) :void
  {
    this.jumbotronSource.next(message)
  }

  getBreadcrumb(): Observable<any>
  {
    let breadcrumbs = localStorage.getItem('breadcrumbs') ? JSON.parse(localStorage.getItem('breadcrumbs')) : {};
    this.breadcrumbs.next(breadcrumbs);
    return this.breadcrumbs.asObservable();
  }

  setBreadcrumb(breadcrumbs: any) :void
  {
    let cb = localStorage.getItem('breadcrumbs') ? JSON.parse(localStorage.getItem('breadcrumbs')) : {};
    breadcrumbs = Object.assign(cb, breadcrumbs);
    localStorage.setItem('breadcrumbs', JSON.stringify(breadcrumbs));
    this.breadcrumbs.next(breadcrumbs);
  }

  /* Check this method */
  getCurrentModule():Module
  {
    return JSON.parse(localStorage.getItem('cmodule'));
  }

  setCurrentModule(module:Module):void
  {
    localStorage.setItem('cmodule', JSON.stringify(module));
  }

  /* Check this method */
  getCurrentMasterclass() :Module
  {
    return JSON.parse(localStorage.getItem('cmasterclass'));
  }

  setCurrentMasterclass(masterclass:Module):void
  {
    localStorage.setItem('cmasterclass', JSON.stringify(masterclass));
  }

  /* Check this method */
  getCurrentLesson() :Lesson
  {
    return JSON.parse(localStorage.getItem('clesson'));
  }

  setCurrentLesson(lesson:Lesson) :void
  {
    localStorage.setItem('clesson', JSON.stringify(lesson));
  }

  private removeCurrentItem(key:string) {
    localStorage.removeItem(key);
  }

  /* Check this method */
  getCourseProperty(slug:string, property:string): any
  {
    const crs = this.coursesStore.courses.filter((course:Course) => course.slug === slug)[0];
    if(crs)
      return crs[property as keyof Course];
    return 0;
  }

  loadAll() :void
  {
    if(this.coursesStore.courses.length > 0) {
      this._courses.next(Object.assign({}, this.coursesStore).courses);
    } else {
      this.http.get<ApiResponse>(this.baseUrl + 'my').subscribe({
        next: (result:ApiResponse) => this.updateCoursesStore(result),
        error : (e) => console.log(e),
        complete: () => console.log('complete')
      });
    }
    // return observableOf(this.courses);
  }

  private updateCoursesStore(result:ApiResponse) :void
  {
    if(result.status == 'ok') {
      let data = result.data;
      this.coursesStore.courses = data;
      var hasTygpro = data.find((course:Course) => course.slug == 'tyg-pro') ? true : false;
      this.hasTygpro.next(hasTygpro);
      var hasAces = data.find((course:Course) => course.slug == 'aces') ? true : false;
      this.hasAces.next(hasAces);
      this._courses.next(Object.assign({}, this.coursesStore).courses);
    }
  }

  load(slug: string) :void
  {
    if(this.courseStore.course.slug === slug) {
      this._course.next(Object.assign({}, this.courseStore).course);
    } else {
      this.http.get<ApiResponse>(this.baseUrl + 'course/'+slug).subscribe({
        next : (result:ApiResponse) => this.updateCourseStore(slug, result),
        error : (e) => console.log(e),
        complete: () => console.log('complete')
      });
    }
  }

  private updateCourseStore(slug:string, result:ApiResponse) :void
  {
    if(result.status == 'ok') {
      let notFound = true;
      let data = result.data;
      this.setBreadcrumb({course:data.name});
      this.courseStore.course = data;
      this.progressService.load(data.id);
      this.directoryService.loadAll(data.id);
      this.eventService.loadAll(data.id);
      if(slug != 'aces' && slug != 'tyg-pro')
        this.faqService.loadAll(data.id);
      this.coursesStore.courses.forEach((item, index) => {
        if (item.id === data.id) {
          const curcorse = Object.assign(this.coursesStore.courses[index], data);
          this.coursesStore.courses[index] = curcorse;
          notFound = false;
        }
      });
      if (notFound) {
        this.coursesStore.courses.push(data);
      }
      this._courses.next(Object.assign({}, this.coursesStore).courses);
      this._course.next(Object.assign({}, this.courseStore).course);
    } else {
      this.router.navigate(['courses','my-courses']);
    }
    // this.currentcourse.next(data);
  }

  unload() :void
  {
    this.hasTygpro.next(false);
    this.coursesStore.courses = [];
    this._courses.next(Object.assign({}, this.coursesStore).courses);
    this.courseStore.course = this.defaultValue;
    this._course.next(Object.assign({}, this.courseStore).course);
    this.removeCurrentItem('clesson');
    this.removeCurrentItem('cmodule');
    this.removeCurrentItem('cmasterclass');
    localStorage.setItem('breadcrumbs', JSON.stringify({}));
    this.progressService.unload();
    this.directoryService.unload();
    this.eventService.unload();
    this.faqService.unload();
  }

  getModule(id: string|number): Observable<ApiResponse> {
    return this.http.get<ApiResponse>(this.baseUrl + 'course/module/'+id);
  }

  getMasterclass(id: string|number): Observable<ApiResponse> {
    return this.http.get<ApiResponse>(this.baseUrl + 'course/masterclass/'+id);
  }

  getNextUnlock(): Observable<ApiResponse> {
    return this.http.get<ApiResponse>(this.baseUrl + 'course/masterclasses/nextunlock');
  }

  /* check this method */
  hasAccesstoMasterclass(slug:string) :boolean
  {
    if(this.courseStore.course.hasOwnProperty('slug')) {
      let course = this.courseStore.course;
      let modules = course.modules.concat(course.masterclasses);
      let module = modules.filter((module:Module) => module.slug === slug)[0];
      return module.is_owned == 1 ? true : false;
    }
    return false;
  }

  getLesson(course:string, lessonsslug: string): Observable<ApiResponse> {
    return this.http.get<ApiResponse>(this.baseUrl + 'course/'+course+'/lesson/'+lessonsslug);
  }
  /* Check This method */
  async unlockMasterclass(postData:any) :Promise<boolean>
  {
    let promise:boolean = await new Promise((resolve, reject) => {
      this.http.post<ApiResponse>(this.baseUrl +'course/masterclass/unlock', postData)
      .toPromise()
      .then((result:ApiResponse) => {
        if(result.status == "ok" && this.courseStore.course.hasOwnProperty('masterclasses')) {
          const indx = this.courseStore.course.masterclasses.findIndex((masterclass:Module) => masterclass.id === postData.masterclass_id);
          const masterclass = Object.assign(this.courseStore.course.masterclasses[indx], {is_owned:1});
          this.courseStore.course.masterclasses[indx] = masterclass;
          this._course.next(Object.assign({}, this.courseStore).course);
          resolve(true);
        } else {
          resolve(false);
        }
      },
      msg => reject(false));
    });
    return promise;
  }

  async addToNextStep(postData:Object) :Promise<ApiResponse>
  {
    let promise:ApiResponse = await new Promise((resolve, reject) => {
      this.http.post<ApiResponse>(this.baseUrl +'course/lesson/action', postData)
      .toPromise()
      .then((result:ApiResponse) => {
        if(result.status == "ok" && this.courseStore.course.hasOwnProperty('nextsteps')) {
          this.courseStore.course.nextsteps.push(result.data);
          this._course.next(Object.assign({}, this.courseStore).course);
          resolve(result);
        } else {
          resolve({'msg':'Could not add to next step', 'status':'error'});
        }
      },
      msg =>reject({'msg':'Could not add to next step', 'status':'error'})
    );
    });
    return promise;
  }

  /* Check This method */
  async activityReview(id:number, postData:Object) :Promise<ApiResponse>
  {
    let promise:ApiResponse = await new Promise((resolve, reject) => {
      this.http.put<ApiResponse>(this.baseUrl +'course/lesson/action/'+id, postData)
      .toPromise()
      .then(result => {
        if(result.status == "ok" && this.courseStore.course.hasOwnProperty('nextsteps')) {
          const indx = this.courseStore.course.nextsteps.findIndex((action:NextStep) => action.id === id);
          const action = Object.assign(this.courseStore.course.nextsteps[indx], result.data);
          this.courseStore.course.nextsteps[indx] = action;
          this._course.next(Object.assign({}, this.courseStore).course);
          resolve(result);
        } else {
          resolve({'msg':'Could not review', 'status':'error'});
        }
      },
      msg => reject({'msg':'Could not review', 'status':'error'})
    );
    });
    return promise;
  }

  async skipPwyc(nextdate:string, course:Course): Promise<ApiResponse>
  {
    let promise:ApiResponse = await new Promise((resolve, reject) => {
      this.http.post<ApiResponse>(this.baseUrl + 'hcu/pwyc/skip', {'nextdate':nextdate, course:course.slug})
      .toPromise()
      .then((result:ApiResponse) => {
        if(result.status == "ok") {
          this.updatePywcCurses(nextdate, course);
          resolve(result);
        } else {
          reject({'msg':'Could not not skip next step', 'status':'error'})
        }
      },
      msg => reject({'msg':'Could not skip next step', 'status':'error'})
    );
    });
    return promise;
  }

  updatePwyc(postData:Object, course:Course): Observable<ApiResponse>
  {
    return this.http.put<ApiResponse>(this.baseUrl + 'hcu/pwyc', postData)
    .pipe(tap(result => {
      if(result.status == 'ok') {
        this.updatePywcCurses('2031-12-31 23:59:59', course);
      }
    }));
  }

  updatePywcCurses(enddate:string, course:Course) :void
  {
    let ind = this._pwyccourses.findIndex(p => p.includes(course.id));
    if(ind > -1) {
      let pwyccourses = this._pwyccourses[ind];
      pwyccourses.forEach((id, index) => {
        const indx = this.coursesStore.courses.findIndex((course:Course) => course.id === id);
        if(indx > -1 ) {
          const course = Object.assign(this.coursesStore.courses[indx], {course_enddate:enddate});
          this.coursesStore.courses[indx] = course;
        }
      });
      this._courses.next(Object.assign({}, this.coursesStore).courses);
    }
  }

}
