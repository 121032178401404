<nb-list *ngIf="!loading">
  <nb-list-item class="lessonitem border-0" *ngFor="let lesson of getLessons(module)" [ngSwitch]="!checkAccess(lesson) || !link">
    <div class="d-flex align-items-center w-100" *ngSwitchCase="true" nbTooltipPlacement="top" [nbTooltip]="getTooltip(lesson)" nbTooltipStatus="danger">
      <div class="flex-shrink-0">
        <nb-icon *ngIf="icon" icon="lock" status="basic" class="h2 mb-0 align-self-top"></nb-icon>
      </div>
      <div class="flex-grow-1 ms-3">
        <img *ngIf="lesson.options.icon" [src]="lesson.options.icon" alt="track" class="img-fluid d-inline-block float-start me-2" width="22"/>
        <!-- <nb-icon icon="arrow-ios-forward" class="me-2 mb-0 align-self-middle float-end larrow"></nb-icon> -->
        <div class="badge-wrap d-inline mb-2">
          <span *ngIf="lesson.options.tag.text" class="badge rounded-pill text-bg-{{lesson.options.tag.color}} me-2 float-start">{{lesson.options.tag.text}}</span>
          <span *ngIf="lesson.options.track" class="badge rounded-pill text-bg-info me-2 float-start">Track {{lesson.options.track == 3 ? '1+2' : lesson.options.track}}</span>
        </div>
        <h3 class="mb-0 ltitle" [line-truncation]="2">{{lesson.name}}</h3>
        <!-- <p>Can Click 2 - {{lesson.is_dependent != 0 && !checkAccess(lesson)}} - {{lesson.is_dependent}} - {{lesson.start_date}} - {{course.options.progressby}}</p> -->
      </div>
    </div>
    <div class="d-flex align-items-center pointer w-100" (click)="goToLesson(course.slug, module, lesson)" *ngSwitchCase="false">
      <div class="flex-shrink-0">
        <nb-icon *ngIf="icon" [icon]="getIcon(lesson.id, lesson.is_dependent)" [status]="getIconStatus(lesson.id, lesson.is_dependent)" class="h2 mb-0 align-self-top"></nb-icon>
      </div>
      <div class="flex-grow-1 ms-3">
        <img *ngIf="lesson.options.icon" [src]="lesson.options.icon" alt="track" class="img-fluid d-inline-block float-start me-1" width="22"/>
        <!-- <nb-icon icon="arrow-ios-forward" class="me-2 mb-0 align-self-middle float-end larrow"></nb-icon> Modified remove this-->
        <div class="badge-wrap d-inline mb-2">
          <span *ngIf="lesson.options.tag.text" class="badge rounded-pill text-bg-{{lesson.options.tag.color}} me-2 float-start">{{lesson.options.tag.text}}</span>
          <span *ngIf="lesson.options.track" class="badge rounded-pill text-bg-info me-2 float-start">Track {{lesson.options.track == 3 ? '1+2' : lesson.options.track}}</span>
        </div>
        <h3 class="mb-0 ltitle" [line-truncation]="2">{{lesson.name}}</h3>
        <!-- <p>Can Click - {{lesson.is_dependent != 0 && !checkAccess(lesson)}} - {{lesson.is_dependent}} - {{lesson.start_date}} - {{course.options.progressby}}</p> -->
        <!-- <div [line-truncation]="2" [innerHTML]="getLessonName(lesson)"></div> -->
        <!-- <p class="text-disabled mt-1 mb-0" *ngIf="lesson.is_dependent != 0 && !checkLessonProgress(lesson.is_dependent)"></p> -->
      </div>
    </div>
  </nb-list-item>
</nb-list>
<div *ngIf="loading">
  <nb-list-item class="px-0 border-top-0 py-4" *ngFor="let discussion of getArray(5)">
    <ngx-placeholder type="lesson" bg="dark" class="w-100"></ngx-placeholder>
  </nb-list-item>
</div>
