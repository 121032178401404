import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'environments/environment';
import { of as observableOf,  Observable,  BehaviorSubject, timer } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';
import { FormGroup, FormControl, FormBuilder, Validators, AbstractControl, ValidationErrors, AsyncValidatorFn } from '@angular/forms'
import { ApiResponse } from 'app/@core/data/api-response';
import { MasterCourse, MasterLesson, MasterModule, MasterData } from '../data/master';
import { Form } from '../data/form';
import { NgxValidator } from 'app/@core/validator';
import * as moment from 'moment-timezone';
// import * as FileSaver from 'file-saver';
import * as XLSX from 'xlsx';


@Injectable(
  {providedIn: 'root'}
)
export class MasterService extends MasterData {

  baseUrl:string = environment.baseUrl+'courses/';
  readonly today:moment.Moment = moment().tz('America/New_York');
  readonly endday:moment.Moment = moment('2030-12-31 00:00:00').tz('America/New_York');

  defaultValue:MasterCourse = {id:0, name:'', slug:'', description:'', start_date:'', end_date:'', status:1 ,options: {logo:'',is_cohort:false, beacon:''}, modules:'', masterclasses:'', created_date: this.formatDate(this.today)};

  private _courses = new BehaviorSubject<MasterCourse[]>([]);
  private coursesStore: { courses: MasterCourse[] } = { courses: [] };
  readonly courses = this._courses.asObservable();

  private _course: BehaviorSubject<MasterCourse> = new BehaviorSubject<MasterCourse>(this.defaultValue);
  private courseStore: { course: MasterCourse } = { course: this.defaultValue};
  readonly course = this._course.asObservable();

  private _forms = new BehaviorSubject<Form[]>([]);
  private formStore: { forms: Form[] } = { forms: [] };
  readonly forms = this._forms.asObservable();

  cForm: FormGroup;
  mForm: FormGroup;
  lForm: FormGroup;
  eForm: FormGroup;
  bForm: FormGroup;
  sForm: FormGroup;
  gcaForm: FormGroup;
  libForm: FormGroup;

  fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
  fileExtension = '.xlsx';

  constructor(
    private http: HttpClient,
    private fb:FormBuilder,
  ) {super()}

  formatDate(datetime:moment.Moment, format:string = 'YYYY-MM-DD HH:mm:ss') :string
  {
    return datetime.format(format);
  }

  formatMoment(datetime: string) :moment.Moment
  {
    return moment.tz(datetime, 'America/New_York');
  }

  getByUrl(url: string):Observable<ApiResponse> {
    return this.http.get<ApiResponse>(url);
  }

  get(slug: string) :Observable<ApiResponse>
  {
    return this.http.get<ApiResponse>(this.baseUrl+slug);
  }

  post(slug: string, data:any) :Observable<ApiResponse>
  {
    return this.http.post<ApiResponse>(this.baseUrl+slug, data);
  }

  put(slug: string, data:any) :Observable<ApiResponse>
  {
    return this.http.put<ApiResponse>(this.baseUrl+slug, data);
  }

  delete(slug: string) :Observable<ApiResponse>
  {
    return this.http.delete<ApiResponse>(this.baseUrl+slug);
  }

  loadAll() :void
  {
    // return this.http.get<ApiResponse>(this.baseUrl + 'all');
    this.http.get<ApiResponse>(this.baseUrl + 'admin/courses').subscribe({
      next: (result:ApiResponse) => {
        if(result.status == 'ok') {
          this.coursesStore.courses = result.data;
          this._courses.next(Object.assign({}, this.coursesStore).courses);
          this.loadForms();
        }
      },
      error : (e) => console.log(e),
      complete: () => console.log('complete')
    });
  }

  loadCourse(id:number) :void
  {
    if(this.courseStore.course.hasOwnProperty('id') && this.courseStore.course.id == id) {
      this._course.next(Object.assign({}, this.courseStore).course);
    } else {
      this.http.get<ApiResponse>(this.baseUrl + 'admin/courses/'+id).subscribe({
        next: (result:ApiResponse) => {
          if(result.status == 'ok') {
            this.courseStore.course = result.data;
            this._course.next(Object.assign({}, this.courseStore).course);
          }
        },
        error : (e) => console.log(e),
        complete: () => console.log('complete')
      });
    }
  }

  unload() :void
  {
    this.courseStore.course = this.defaultValue;
    this._course.next(Object.assign({}, this.courseStore).course);
  }

  loadForms() :void
  {
    this.http.get<ApiResponse>(this.baseUrl + 'admin/form').subscribe({
      next: (result:ApiResponse) => {
        if(result.status == 'ok') {
          this.formStore.forms = result.data;
          this._forms.next(Object.assign({}, this.formStore).forms);
        }
      },
      error : (e) => console.log(e),
      complete: () => console.log('complete')
    });
  }

  updateCoursesStore(course:MasterCourse, action:string) :void
  {
    if(action == 'add') {
      this.coursesStore.courses.push(course);
    } else {
      this.coursesStore.courses.forEach((item, index) => {
        if (item.id === course.id) {
          if(action == 'remove') {
            this.coursesStore.courses.splice(index, 1);
          } else {
            this.coursesStore.courses[index] = course;
          }
        }
      });
    }
    this._courses.next(Object.assign({}, this.coursesStore).courses);
  }

  updateCourseStore(course:MasterCourse, action:string) :void
  {
    const md = Object.assign(this.courseStore.course, course);
    this._course.next(Object.assign({}, this.courseStore).course);
  }

  updateCourseModuleStore(module:MasterModule, action:string) :void
  {
    if(action == 'add') {
      module.lessons = [];
      module.created_date = this.formatDate(this.today);
      this.courseStore.course.modules.push(module);
    } else if(action == 'update') {
      const indx = this.courseStore.course.modules.findIndex((mdl:MasterLesson) => mdl.id === module.id);
      const md = Object.assign(this.courseStore.course.modules[indx], module);
      this.courseStore.course.modules[indx] = md;
    }
    this._course.next(Object.assign({}, this.courseStore).course);
  }

  updateCourseMasterclassStore(masterclass:MasterModule, action:string) :void
  {
    if(action == 'add') {
      masterclass.lessons = [];
      masterclass.created_date = this.formatDate(this.today);
      this.courseStore.course.masterclasses.push(masterclass);
    } else if(action == 'update') {
      const indx = this.courseStore.course.masterclasses.findIndex((mc:MasterModule) => mc.id === masterclass.id);
      const mc = Object.assign(this.courseStore.course.masterclasses[indx], masterclass);
      this.courseStore.course.masterclasses[indx] = mc;
    }
    this._course.next(Object.assign({}, this.courseStore).course);
  }

  updateCourseModuleLessonStore(lesson:MasterLesson, action:string) :void
  {
    const indx = this.courseStore.course.modules.findIndex((mdl:MasterModule) => mdl.id === lesson.module_id);
    if(action == 'add') {
      this.courseStore.course.modules[indx].lessons.push(lesson);
    } else if(action == 'update') {
      const lsnindx = this.courseStore.course.modules[indx].lessons.findIndex((lsn:MasterLesson) => lsn.id === lesson.id);
      const ls = Object.assign(this.courseStore.course.modules[indx].lessons[lsnindx], lesson);
      this.courseStore.course.modules[indx].lessons[lsnindx] = ls;
    }
    this._course.next(Object.assign({}, this.courseStore).course);
  }

  /*  checked and commented : Method not in use */
  // updateFormStore(form, action) {
  //   let notFound = true;
  //   this.formStore.forms.forEach((item, index) => {
  //     if (item.id === form.id) {
  //       this.formStore.forms[index] = form;
  //       notFound = false;
  //     }
  //   });
  //   if (notFound) {
  //     this.formStore.forms.push(form);
  //   }
  //   this._forms.next(Object.assign({}, this.formStore).forms);
  // }

  searchValue(text:string, table:string, key:string): Observable<ApiResponse> {
    // debounce
    return timer(500)
    .pipe(
      switchMap(() => {
        // Check if username is available
        return this.http.get<ApiResponse>(this.baseUrl + 'admin/search/'+table+'?key='+key+'&value='+text)
      })
    );
  }

  slugValidator(table:string): AsyncValidatorFn  | null {
    return (control: AbstractControl): Observable<ValidationErrors | null> => {
      return this.searchValue(control.value, table, 'slug')
      .pipe(
        map((res:ApiResponse) => {
          // if username is already taken
          if (res.status == 'ok') {
            // return error
            return { 'slugExists': true};
          }
          return null;
        })
      );
    };
  }

  emailValidator(table:string): AsyncValidatorFn | null {
    return (control: AbstractControl): Observable<ValidationErrors | null> => {
      return this.searchValue(control.value, table, 'email')
      .pipe(
        map((res:ApiResponse) => {
          // if username is already taken
          if (res.status == 'ok') {
            // return error
            return { 'emailExists': true};
          }
          return null;
        })
      );
    };
  }

  /* In admin only */
  courseForm() :FormGroup
  {
    this.cForm = this.fb.group({
      name: ['', Validators.required],
      slug: ['', [Validators.required, Validators.minLength(3), NgxValidator.noSpaceValidator],this.slugValidator('courses')],
      description: ['', [Validators.required, Validators.maxLength(150)]],
      start_date: [this.formatDate(this.today)],
      end_date: ['2030-12-31 00:00:00'],
      status:1,
      options: this.fb.group({
        logo: ['', [Validators.required, Validators.pattern('(https?://)?([\\da-z.-]+)\\.([a-z.]{2,6})[/\\w .-]*/?')]],
        beacon: [""],
        slack: [""],
        mist: [""],
        today: [""],
        actionplan:[false],
        is_cohort: [false],
        directory: [false],
        progressby:[""]
      })
    });
    return this.cForm;
  }

  /* In admin only */
  moduleForm(cid:number, mid:number|string) :FormGroup
  {
    this.mForm = this.fb.group({
      course_id: cid,
      name: ['', Validators.required],
      slug: [{ disabled: mid != 'module' ? true : false, value: '' }, [Validators.required, Validators.minLength(3), NgxValidator.noSpaceValidator], this.slugValidator('course/modules')],
      description: ['', [Validators.maxLength(700)]],
      start_date: [this.formatDate(this.today), Validators.required],
      end_date: ['2030-12-31 00:00:00', Validators.required],
      content: '',
      expert_id: 0,
      is_dependent:0,
      is_pack:0,
      status:1,
      options: this.fb.group({
        cta: this.fb.group({'link':new FormControl(''),'text':new FormControl(''),'heading':new FormControl(''),'target':new FormControl('')}),
        video: this.fb.group({
          id: new FormControl(''),
          type: new FormControl(''),
          actions: this.fb.array([])
        })
      })
    });
    return this.mForm;
  }

  /* In admin only */
  lessonForm(cid:number, mid:number, lid:number|string) :FormGroup
  {
    this.lForm = this.fb.group({
      course_id: cid,
      module_id: mid,
      slug: [{ disabled: lid != 'lesson' ? true : false, value: '' }, [Validators.required, Validators.minLength(3), NgxValidator.noSpaceValidator], this.slugValidator('course/lessons')],
      type: ['', Validators.required],
      name: ['', Validators.required],
      description: [''],
      content:['null'],
      is_dependent:0,
      start_date: [this.formatDate(this.today), Validators.required],
      end_date: ['2030-12-31 00:00:00', Validators.required],
      status:0,
      options: this.fb.group({
        cta: this.fb.group({'link': new FormControl(''),'text':new FormControl(''),'heading':new FormControl(''),'target':new FormControl('_blank')}),
        icon:new FormControl(''),
        track:new FormControl(''),
        tag:this.fb.group({
          text: new FormControl(''),
          color:new FormControl('')
        }),
        video: this.fb.group({
          id: new FormControl(''),
          type: new FormControl(''),
          actions: this.fb.array([])
        })
      })
    });
    return this.lForm;
  }

  /* In admin only */
  eventForm() :FormGroup
  {
    this.eForm = this.fb.group({
      name: ['', Validators.required],
      course_id: ['', Validators.required],
      type: [''],
      topic: ['', Validators.required],
      description: ['', [Validators.required, Validators.maxLength(250)]],
      datetime: ['', Validators.required],
      enddate: ['', Validators.required],
      timezone:['America/New_York'],
      status:[1],
      options: this.fb.group({
        location: ['', [Validators.required, Validators.pattern('(https?://)?([\\da-z.-]+)\\.([a-z.]{2,6})[/\\w .-]*/?')]],
        video: this.fb.group({
          id: new FormControl(''),
          type: new FormControl(''),
          actions: this.fb.array([])
        })
      })
    });
    return this.eForm;
  }

  /* In admin only */
  faqForm(cid:number, mid:number, lid:number) :FormGroup
  {
    return this.fb.group({
      course_id: [cid],
      module_id:[mid,],
      lesson_id:[lid],
      user_id:0,
      question:['', Validators.required],
      answer:['', Validators.required],
      is_public: [1],
      status:[1]
    });
  }

  /* In admin only */
  studentForm() :FormGroup
  {
    this.sForm = this.fb.group({
      role: ['student'],
      firstname: ['', [Validators.required, Validators.maxLength(40)]],
      lastname: ['', [Validators.required, Validators.maxLength(40)]],
      email: ['', [Validators.required, Validators.pattern('^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$')],this.emailValidator('users')],
      password:['$2y$12$AYwcPMQMlpD3TVMtw85LU.33pXn1MzHbC81o2sC/6lMYlZSxOQoAu'],
      company:['', [Validators.required, Validators.maxLength(40)]],
      street:[''],
      city: ['',[Validators.required, Validators.maxLength(20)]],
      state: ['',[Validators.required, Validators.maxLength(10)]],
      zip: ['',[Validators.required, Validators.maxLength(10)]],
      country: ['',[Validators.required, Validators.maxLength(40)]],
      website: [''],
      timezone: [''],
      status: [1],
      in_directory:[1],
      industry_id: [null, [Validators.required]],
      bio: ['', [Validators.required, Validators.maxLength(1000)]]
    });
    return this.sForm;
  }

  /* In admin only */
  grantCourseAccessForm(user_id:number) :FormGroup
  {
    this.gcaForm = this.fb.group({
      user_id: [user_id],
      course_id: ['', [Validators.required]],
      start_date: [this.formatDate(this.today)],
      end_date: [this.formatDate(this.endday)],
      options:this.fb.group({}),
      status: [1],
      created_by: [2],
      updated_by: [2]
    });
    return this.gcaForm;
  }

  /* In admin only */
  libraryForm(cid:number, lid:number|string) :FormGroup
  {
    this.libForm = this.fb.group({
      course_id: cid,
      cat_id: ['', Validators.required],
      slug: [{ disabled: ['new','buzz-call','resource'].indexOf(lid.toString()) == -1 ? true : false, value: '' }, [Validators.required, Validators.minLength(3), NgxValidator.noSpaceValidator], this.slugValidator('course/library')],
      type: 'null',
      name: ['', Validators.required],
      description: ['', [Validators.maxLength(700)]],
      content: '',
      is_featured: 0,
      start_date: [this.formatDate(this.today), Validators.required],
      end_date: ['2030-12-31 00:00:00', Validators.required],
      options: this.fb.group({
        image:new FormControl(''),
        cta: false,
        tag:this.fb.group({
          text: new FormControl(''),
          color:new FormControl('')
        }),
        video: this.fb.group({
          id: new FormControl(''),
          type: new FormControl('vimeo'),
          title:new FormControl(''),
          duration:new FormControl(''),
          actions: this.fb.array([])
        }),
        downloads: this.fb.array([])
      }),
      status:1,
      created_by: ['', Validators.required],
    });
    return this.libForm;
  }

  public exportData(jsonData: any[], fileName: string): void {
    const ws: XLSX.WorkSheet = XLSX.utils.json_to_sheet(jsonData);
    const wb: XLSX.WorkBook = { Sheets: { 'data': ws }, SheetNames: ['data'] };
    const excelBuffer: any = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
    this.saveExcelFile(excelBuffer, fileName);
  }

  private saveExcelFile(buffer: any, fileName: string): void {
    const data: Blob = new Blob([buffer], {type: this.fileType});
    // FileSaver.saveAs(data, fileName + this.fileExtension);
  }

}
